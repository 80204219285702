<template>
  <!-- welcome section -->
  <v-card flat class="ma-4 dashboardPanel">
    <v-row class="d-flex flex-wrap">
      <v-col>
        <v-btn
          x-large
          block
          :to="{ name: 'Reports List', params: { viewMode: 'create' } }"
          color="primary"
        >
          <v-icon class="mr-2" color="white">mdi mdi-plus</v-icon>
          <span class="white--text">{{ $t("quick_report") }}</span>
        </v-btn>
      </v-col>
      <v-col>
        <v-btn
          x-large
          block
          :to="{ name: 'Reports Plan', params: { viewMode: 'schedule' } }"
          color="lightYellow"
        >
          <v-icon class="mr-2" color="white">mdi mdi-clock</v-icon>
          <span class="white--text">{{ $t("schedule") }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "GsReportWelcomeButtons",
};
</script>
