<template>
  <v-sheet :height="height" width="100%" color="white" class="loader-top">
    <v-row class="fill-height" align="center" justify="center" fluid>
      <v-progress-circular
        :width="width"
        :size="size"
        :color="color"
        indeterminate
        >{{ $t(text) }}</v-progress-circular
      >
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  name: "GsCircularLoader",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    size: {
      type: Number,
      default: 150,
    },
    width: {
      type: Number,
      default: 8,
    },
    text: {
      type: String,
      default: "loading",
    },
    height: {
      type: String,
      default: "350",
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.loader-top {
  margin-top: 16px;
}
</style>
