<template>
  <!-- upcoming reports section -->
  <v-hover v-slot:default="{ hover }" close-delay="100">
    <v-card
      class="ma-4 dashboardPanel frameYellow"
      outlined
      :elevation="hover ? 6 : 0"
    >
      <v-card-title class="lightYellow">
        <v-icon class="mr-3 title-icon">mdi-fast-forward</v-icon>
        {{ $t("upcoming_reports") }}
      </v-card-title>
      <v-card-text>
        <GsCircularLoader v-if="inProgress && !noData" color="orange" />
        <v-list
          v-if="!inProgress && !noData"
          dense
          height="500px"
          style="overflow: scroll"
        >
          <template v-for="(item, index) in tableData">
            <v-list-item :key="index">
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  item.nextRun | getDisplayDate
                }}</v-list-item-subtitle>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.description
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                style="justify-content: space-around"
                class="float-right"
              >
                <v-icon class="ml-auto mb-1" small dense>mdi-clock</v-icon>
                <v-list-item-action-text>{{
                  item.nextRun | getDisplayDate("estimate")
                }}</v-list-item-action-text>
              </v-list-item-action>
              <v-list-item-action
                style="justify-content: space-around"
                class="float-right"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      icon
                      v-bind="attrs"
                      v-on="on"
                      :to="{
                        name: 'Scheduled Report Details',
                        params: { taskId: item.taskId },
                      }"
                    >
                      <v-icon>mdi mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("details") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-divider
              v-if="index + 1 < tableData.length"
              :key="'hr' + index"
            ></v-divider>
          </template>
        </v-list>
        <span v-if="inProgress && noData" class="subtitle-1">{{
          $t("no_data")
        }}</span>
      </v-card-text>
      <v-card-actions class="pl-4 pr-4 pb-3 pt-0">
        <template v-if="!noData">
          <v-menu>
            <template v-slot:activator="{ on }">
              <span class="caption mr-2">{{ $t("rows_per_page") }}:</span>
              <v-btn
                outlined
                color="grey darken-2"
                v-on="on"
                width="40"
                height="30"
              >
                <span class="caption">{{ limit }}</span>
                <v-icon right x-small>mdi mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="setLimit(item)"
                v-for="(item, index) in itemNumbers"
                :key="index"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            :to="{ name: 'Reports Plan' }"
            small
            class="ml-auto mb-2 lightYellow"
            >{{ $t("show_all_scheduled_reports") }}</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import GsCircularLoader from "@/components/GsCircularLoader";

export default {
  name: "GsReportPlanExtract",
  components: {
    GsCircularLoader,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 15,
    },
  },
  watch: {
    tableData: {
      deep: true,
      handler() {
        this.dataLoaded();
      },
    },
  },
  data() {
    return {
      noData: false,
      itemNumbers: [10, 15, 20, 25, 50],
    };
  },
  computed: {
    inProgress() {
      if (this.tableData.length === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    dataLoaded() {
      this.noData = this.tableData.length === 0;
    },
    setLimit(limit) {
      this.$emit("change", limit);
    },
  },
};
</script>

<style lang="scss" scoped></style>
