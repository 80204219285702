<template>
  <!-- last reports section -->
  <v-hover v-slot:default="{ hover }" close-delay="100">
    <v-card
      class="ma-4 dashboardPanel frameBlue"
      outlined
      :elevation="hover ? 6 : 0"
    >
      <v-card-title class="darkBlue">
        <v-icon class="mr-3 title-icon">mdi-view-list</v-icon>
        {{ $t("latest_reports") }}
      </v-card-title>
      <v-card-text>
        <GsCircularLoader v-if="inProgress && !noData" />
        <v-list
          v-if="!inProgress && !noData"
          dense
          height="500px"
          style="overflow: scroll"
        >
          <template v-for="(item, index) in tableData">
            <v-list-item
              :key="index"
              v-if="index < limit"
              @click="riport_click(item)"
            >
              <v-list-item-content>
                <v-list-item-subtitle>{{
                  item.updatedAt | getDisplayDate
                }}</v-list-item-subtitle>
                <v-list-item-title>{{
                  item.$calculated.$templateName
                }}</v-list-item-title>
                <v-list-item-subtitle v-show="item.inputValues.endDate"
                  >{{ item.inputValues.startDate | getDisplayDate("date") }} -
                  {{
                    item.inputValues.endDate | getDisplayDate("date")
                  }}</v-list-item-subtitle
                >
                <v-list-item-subtitle>{{
                  showAssets(item.inputValues.items)
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="my-auto">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="
                        downloadReport(item.reportId, item.format, $event)
                      "
                      :color="showReportFormatColor(item.format)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ showReportFormat(item.format) }}
                    </v-icon>
                  </template>
                  <span>{{ $t("download") }}</span>
                </v-tooltip>
              </v-list-item-icon>
            </v-list-item>
            <v-divider
              v-if="index + 1 < tableData.length && index < limit"
              :key="'hr' + index"
            ></v-divider>
          </template>
        </v-list>
        <span v-if="inProgress && noData" class="subtitle-1">{{
          $t("no_data")
        }}</span>
      </v-card-text>
      <v-card-actions class="pl-4 pr-4 pb-3 pt-0">
        <template v-if="!noData">
          <v-menu>
            <template v-slot:activator="{ on }">
              <span class="caption mr-2">{{ $t("rows_per_page") }}:</span>
              <v-btn
                outlined
                color="grey darken-2"
                v-on="on"
                width="40"
                height="30"
              >
                <span class="caption">{{ limit }}</span>
                <v-icon right x-small>mdi mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="setLimit(item)"
                v-for="(item, index) in itemNumbers"
                :key="index"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn
            :to="{ name: 'Reports List', params: { viewMode: 'list' } }"
            small
            class="ml-auto mb-2 darkBlue"
            >{{ $t("show_all_reports") }}</v-btn
          >
        </template>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
const { callAPI } = require("ngt-frontend-core").apiOpsBff;
const { fileSave } = require("@/utils/fileSave.js");
import GsCircularLoader from "@/components/GsCircularLoader";

export default {
  name: "GsReportGeneratedExtract",
  components: {
    GsCircularLoader,
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    limit: {
      type: Number,
      default: 15,
    },
  },
  watch: {
    tableData: {
      deep: true,
      handler() {
        this.dataLoaded();
      },
    },
  },
  data() {
    return {
      noData: false,
      itemNumbers: [10, 15, 20, 25, 50],
    };
  },
  computed: {
    inProgress() {
      if (this.tableData.length === 0) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async downloadReport(reportId, format, event) {
      event.stopPropagation();
      try {
        const { data, headers } = await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/reports/${reportId}/fileContent`,
          method: "GET",
          resType: "blob",
        });
        const fileName = headers["content-disposition"]
          ? headers["content-disposition"].match(/filename="(.*)"/)[1]
          : `${reportId}.${format}`;
        fileSave(data, fileName);
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    dataLoaded() {
      this.noData = this.tableData.length === 0;
    },
    setLimit(limit) {
      this.$emit("change", limit);
    },
    riport_click(item) {
      this.$router.push_safe({
        name: "Report Log Details",
        params: { jobId: item.jobId },
      }); /// /jobs/list/job/${jobId}
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.v-data-table-header {
  text-transform: uppercase !important;
}
.iconLink .mdi-file-excel {
  color: $accent !important;
}
.iconLink .mdi-file-pdf {
  color: $red !important;
}
.iconLink .mdi-file-code {
  color: $mOrange !important;
}
</style>
