<template>
  <!-- welcome section -->
  <v-card flat class="ma-4 dashboardPanel">
    <v-card-title>{{ $t("welcome_title") }}</v-card-title>
    <v-card-text>{{ $t("welcome_text") }}</v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "GsReportWelcome",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.create-button {
  max-width: 100px;
  height: 100px;
}
</style>
