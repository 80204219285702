import { render, staticRenderFns } from "./GsCircularLoader.vue?vue&type=template&id=031b4b84&scoped=true"
import script from "./GsCircularLoader.vue?vue&type=script&lang=js"
export * from "./GsCircularLoader.vue?vue&type=script&lang=js"
import style0 from "./GsCircularLoader.vue?vue&type=style&index=0&id=031b4b84&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031b4b84",
  null
  
)

export default component.exports