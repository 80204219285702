<template>
  <v-container fluid>
    <div class="d-flex flex-column flex-lg-row">
      <div class="d-flex flex-column flex-shrink-1">
        <GsReportWelcome />
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <GsReportWelcomeButtons />
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <GsReportGeneratedExtract
        :tableData="generatedReports"
        :limit="reportLimit"
        @change="setReportLimit"
        class="list-box"
      />
      <GsReportPlanExtract
        :tableData="awaitingTasks"
        :limit="planLimit"
        @change="setPlanLimit"
        class="list-box"
      />
    </div>
    <!-- snackbar test -->
    <!-- <v-btn @click="test_click">TEST</v-btn>
    <v-btn @click="test2_click">TEST2</v-btn> -->
  </v-container>
</template>

<script>
const { callBffAPI } = require("ngt-frontend-core").apiOpsBff;

import GsReportWelcome from "@/components/GsReportWelcome";
import GsReportGeneratedExtract from "@/components/GsReportGeneratedExtract";
import GsReportPlanExtract from "@/components/GsReportPlanExtract";
import GsReportWelcomeButtons from "@/components/GsReportWelcomeButtons";
import dayjs from "dayjs";

export default {
  name: "Home",
  components: {
    GsReportWelcome,
    GsReportGeneratedExtract,
    GsReportPlanExtract,
    GsReportWelcomeButtons,
  },
  data() {
    return {
      generatedReports: [],
      awaitingTasks: [],
      reportLimit: this.$store.state.appSettings.dashboard.lastGenerated,
      planLimit: this.$store.state.appSettings.dashboard.upcoming,
    };
  },
  created() {
    // auto re-load of reports
    this.$EventBus.$on("dashboardAPICalls", () => {
      this.getReports();
      this.getTasks();
      this.$EventBus.$emit("timerAttach", "dashboardAPICalls", 600000);
    });
    // please, don't remove the below timer only register the later action, but won't trigger it instantly
    this.getReports();
    this.getTasks();
    this.$EventBus.$emit("timerAttach", "dashboardAPICalls", 600000);
  },
  beforeDestroy() {
    this.$EventBus.$off("dashboardAPICalls");
  },
  methods: {
    async getReports() {
      try {
        // const url = `${process.env.VUE_APP_BFF_ORIGIN}/reports?sort=createdAt:desc&filter=status:eq:report.status.success&limit=${this.reportLimit}`;
        const url = `/reports?sort=createdAt:desc&filter=status:eq:report.status.success&limit=${this.reportLimit}`;
        const result = await callBffAPI({
          url,
          method: "GET",
        });
        this.generatedReports = [];
        if (result.status === 200) {
          const templateIds = result.data.map((p) => p.templateId);
          const templateNames = await this.getTemplateNamesByIds(templateIds);
          for (const report of result.data) {
            report.$calculated = report.$calculated || {};
            report.$calculated.$templateName = templateNames[report.templateId];
            this.generatedReports.push(report);
          }
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    async getTasks() {
      try {
        const url = `/tasks?filter=nextRun:gte:${dayjs().toISOString()},enabled:eq:true&sort=nextRun:asc&limit=${
          this.planLimit
        }`;
        const result = await callBffAPI({
          url,
          method: "GET",
        });
        this.awaitingTasks = [];
        if (result.status === 200) {
          for (const task of result.data) {
            this.awaitingTasks.push(task);
          }
        }
      } catch (error) {
        this.errorSnackbar(error);
      }
    },
    setReportLimit(limit) {
      if (limit > 0) {
        this.$store.commit("setAppSetting", {
          parent: "dashboard",
          key: "lastGenerated",
          value: limit,
        });
        this.reportLimit = limit;
        this.getReports();
      }
    },
    setPlanLimit(limit) {
      if (limit > 0) {
        this.$store.commit("setAppSetting", {
          parent: "dashboard",
          key: "upcoming",
          value: limit,
        });
        this.planLimit = limit;
        this.getTasks();
      }
    },
    test_click() {
      // this.$store.state.snackbar = { text: "TEST", color: "success", timeout: 60000, open: true, multiLine: false, link: "", linkLabel: "" }
      const jobId = "9fd13162-b433-4da3-804e-18a84c2954ff";
      const color = "success";
      const timeout = 10000;
      const link = jobId ? `/jobs/list/job/${jobId}` : "";
      const linkLabel = link
        ? this.$t("report_created_success_link_label")
        : "";
      const text = this.$t("report_created_success");
      const multiLine = false;
      this.$store.state.snackbar = {
        text,
        color,
        timeout,
        open: true,
        multiLine,
        link,
        linkLabel,
      };
    },
    test2_click() {
      this.$store.state.snackbar = { open: false };
    },
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";

.darkBlue {
  background-color: #1976d2 !important;
  color: white !important;
}
.frameBlue {
  border-color: #5ec0ff !important;

  .title-icon {
    color: #5ec0ff !important;
  }

  .v-divider {
    border-color: #5ec0ff !important;
  }
}
.darkYellow {
  background-color: #c69e00 !important;
  color: white !important;
}
.frameYellow {
  border-color: #fecf22 !important;

  .title-icon {
    color: #fecf22 !important;
  }

  .v-divider {
    border-color: #fecf22 !important;
  }
}
.darkGreen {
  background-color: #6aa19d !important;
  color: white !important;
}
.frameGreen {
  border-color: #6aa19d !important;

  .title-icon {
    color: #9ad2ce !important;
  }

  .v-divider {
    border-color: #6aa19d !important;
  }
}
.lightBlue {
  background-color: #5ec0ff !important;
  color: white !important;
}
.frameLightBlue {
  border-color: #0090d4 !important;

  .title-icon {
    color: #0090d4 !important;
  }

  .v-divider {
    border-color: #0090d4 !important;
  }

  .line-chart {
    background-color: #0090d4;
    margin: 20px 10px;
  }
}
.lightYellow {
  background-color: #ebac00 !important;
  color: white !important;
}
.frameLightYellow {
  border-color: #ffc629 !important;

  .title-icon {
    color: #ffc629 !important;
  }

  .v-divider {
    border-color: #ffc629 !important;
  }

  .column-chart {
    margin: 20px 10px;
    color: #ffc629 !important;
  }
}
.iconLink .mdi-file-excel {
  color: $accent !important;
}
.iconLink .mdi-file-pdf {
  color: $red !important;
}
.iconLink .mdi-file-code {
  color: $mOrange !important;
}
.iconLink:hover i {
  top: -4px;
}
.list-box {
  width: 47%;
}

@media (max-width: 960px) {
  .list-box {
    width: auto;
  }
}

@media (max-width: 599px) {
  .dashboardPanel {
    margin: 0;
  }
  .v-application .pa-0 {
    padding: 0 !important;
  }
  .v-card__actions button {
    width: 100%;
  }
}
</style>
